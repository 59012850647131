/* SwitchButton.css */
.switch {
    position: relative;
    display: inline-block;
    width: 57px;
    height: 30px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e73c3c;
    border-radius: 10px;
    transition: 0.5s;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 23px;
    width: 23px;
    left: 4px;
    bottom: 4px;
    background-color: #0d142f;
    border-radius: 10px;
    transition: 0.5s;
  }
  
  input:checked + .slider {
    background-color: hwb(106 13% 5%);
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }